import React from "react"
import { Box, Heading, Text, useDisclosure } from "@chakra-ui/core"
import { FormattedMessage } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import makeBackgroundImageArray from "../helpers/makeBackgroundImageArray"

const NewsIndex = props => {
  const { onOpen, isOpen, onClose } = useDisclosure()
  return (
    <Layout
      location={props.location}
      bg={makeBackgroundImageArray("contact")}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Box pt={12} maxW="800px" my={20}>
        <Heading fontSize="6xl" as="h1" fontWeight="bold">
          <FormattedMessage id="contact.heading" />
        </Heading>
        <Text fontSize="lg" my={3}>
          <FormattedMessage id="contact.subheading" />
        </Text>
      </Box>
    </Layout>
  )
}

export default NewsIndex

export const query = graphql`
  {
    stockImage: file(relativePath: { eq: "stock.jpg" }) {
      childImageSharp {
        fixed(width: 350, height: 240, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
